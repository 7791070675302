.card{
  background: $card-background-color;
  border-bottom: $card-border-width solid $ui-border-color;
  padding: 30px;
  @media #{$mobile} {
    padding: 10px;
  }
}

section.section.card{
  min-height: 50vh;
}
@mixin cardButtonStyle(){
  text-decoration: none;
  color: $font-color;
  @include display-flexbox(column,center);
  @include justify-content(center);
  align-items: center;
  width: 350px;
  height: 190px;
  text-align: center;
  padding: 30px;

  border: $button-border-thickness solid $button-border-color;
  cursor: pointer;

  @include transition();
  &:hover:not([disabled]),
  &.active{
    color: $button-hover-font-color;
    background: $button-hover-bg-color;
    * {
      color: $button-hover-font-color;
    }
    svg *{
      fill: $button-hover-font-color;
    }
  }

  &.disabled{
    @include opacity(.3);
    pointer-events: none;
  }
}
ul.card-list{
  margin: 0;
  padding: 0;
  list-style: none;

  @include display-flexbox(row,center);
  @include justify-content(center);
  @include flex-wrap();
  max-width: 65rem;

  a{
    @include cardButtonStyle();
  }

  li{
    margin: 15px;
  }
}

ul.nav.card-list-grid{
  border-bottom: 1px solid $silver;
  margin: 0;
  width: 100%;
  max-width: 85rem!important;
  margin-left:auto;
  margin-right: auto;
  padding: 1.5rem;
  list-style: none;
  a{
    @include cardButtonStyle();
    padding: 8px;
    width: auto;
    height: 100px;

    h2{
      font-size: 1rem;
      margin: 0;
      line-height: 1rem;
    }
    i,svg{
      width: 3rem;
      height: 3rem;
    }

    .card-hdr{
      @include flex(0,0,auto);

    }
    .card-bdy{
      @include flex(1,1,auto);
    }
  }

  li{
    margin: 0!important;
    padding: 0!important;
    border: none!important;


  }
  @supports (display: grid) {
        display: grid;
        grid-template-columns: lfr lfr lfr lfr ;
        grid-gap: 10px;
        grid-template: auto / repeat(4, 1fr);
        grid-auto-flow: row;
        grid-auto-rows: 100px;
        
  }
  align-items: stretch!important;
  justify-content: stretch;
  justify-items: stretch;
}

.card-radio-button-group{
  @include display-flexbox(row,center);
  @include justify-content(center);
  @include flex-wrap();
  max-width: 85rem;

  &.mini{
    input[type="radio"],
    input[type="checkbox"]{

      + label{
        width: auto;
        height: auto;
      }
    }
  }
  input[type="radio"],
  input[type="checkbox"]{
    display:none;

    + label{
      @extend .card;
      @include cardButtonStyle();
      margin: 10px;
      width: 250px;
    }
    &:checked + label{
      border-color: $button-active-border-color;
      background: $button-active-bg-color;
      color: $button-active-font-color;
      *{
        color: $button-active-font-color;
        fill: $button-active-font-color;
      }
    }
  }
}

form{
  .card{
    padding: 20px;
    //width: 100%;

    margin-bottom: 30px;
  }
}
