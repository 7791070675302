.logo{
  @include flex(0,0,auto);
  background: $nav-background-color;
  @include linear-gradient(lighten($nav-background-color,6), $nav-background-color);
  @include display-flexbox(column,center);
  @include justify-content(center);
  padding: 15px;
  position: relative;
  z-index: 99;

  svg:before{
    content: '';
    display:block;
    position: absolute;
    top: 15px;
    left: 10px;
    right: 10px;
    bottom: 0;
    @include border-radius(100%);
    background: rgba($black,.83);
    @include blur(20px);
    z-index: 0;
  }
  &:hover svg{
    //@include scale(.5);
    @include transform-origin(bottom, center);
  }
  svg{
    max-height: 30vh;
    max-width: 30vh;
    @media #{$desktop}{
      max-height: 30vh;
      max-width: 30vh;
    }
    @media #{$tablet}{
      max-height: 150px;
      max-width: 150px;
    }
    @media #{$ipad-landscape}{
      max-height: 150px;
      max-width: 150px;
    }
    @media #{$ipad-portrait}{
      max-height: 170px;
      max-width: 170px;
    }
    @media #{$ipadpro-landscape}{
      max-height: 150px;
      max-width: 150px;
    }
    @media #{$ipadpro-portrait}{
      max-height: 170px;
      max-width: 170px;
    }
    @media #{$tabletportrait-down}{
      max-height: 150px;
      max-width: 150px;
    }
    z-index: 999999;
    @include transition();
    @include scale(1);

    @media #{$mobile} {
      max-height: 15vh;
      max-width: 15vh;
    }

  }
}
#years-of-service{
  display: none!important;
  @media #{$mobile} {
    display:none!important;
  }
  @media #{$tablet}{
    bottom: 10px;
  }
  @media #{$tablet-landscape}{
    bottom: 0px;
    margin-right: -140px;
  }
  //background: rgba($black,.2);
  padding: 15px;
  font-size: .8rem;
  position: absolute;
  bottom: 0px;
  right: 47%;
  margin-right: -20vh;
  z-index: 99;
  text-align: center;
  color: $white;
  //min-width: 125px;
  @include display-flexbox(row,center);
  > *{
    @include flex(0,0,auto);
  }
  .seal{
    display:block;
    position: relative;
    width: 55px;
    height: 40px;
  }
  .num-years {
   height: 34px;
   width: 34px;
   background: $years-seal-background;
   display: block;
   color: $white;
   position: absolute;
   left: 50%;
   margin-left: -17px;
   @include border-radius(4px);
   font-size: 1rem;
   > span{
     z-index: 9999999999999;
     color: $white;
     font-weight: bold;
     position: absolute;
     width: 100%;
     display:block;
     line-height: 34px;
   }
   &:before{
     height: 34px;
     width: 34px;
     background: $years-seal-background;
     content:"";
     position: absolute;
     display:block;
     /* Rotate */
     -moz-transform: rotate(30deg);
     -webkit-transform: rotate(30deg);
     -ms-transform: rotate(30deg);
     -o-transform: rotate(30deg);
     transform: rotate(30deg);
     left: 50%;
     margin-left: -17px;
     z-index: 1;
     @include border-radius(4px);
   }
   &:after{
     height: 34px;
     width: 34px;
     background: $years-seal-background;
     content:"";
     position: absolute;
     display:block;
     /* Rotate */
     -moz-transform: rotate(-30deg);
     -webkit-transform: rotate(-30deg);
     -ms-transform: rotate(-30deg);
     -o-transform: rotate(-30deg);
     transform: rotate(-30deg);
     left: 50%;
     margin-left: -17px;
     z-index: 1;
     @include border-radius(4px);
   }
  }

}
