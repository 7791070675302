footer.footer-bar{
  background: $footer-background-color;
  color: $footer-font-color;
  padding: 80px;
  font-size: .7rem;
  @media #{$mobile}{
    padding: 20px;
    font-size: .9rem;
  }
  display:block;
  text-align: center;
  @include flex(0,0,auto);
  .disclaimer{
    max-width: $reading-width;
    margin-left: auto;
    margin-right: auto;
  }
  >*{
    margin-bottom: 8px;
    margin-top: 8px;
  }
  p, p a{
    font-size: .7rem;
    @media #{$mobile}{
      font-size: .9rem;
    }
    text-align: left;
    line-height: 1rem;
    color: rgba($white,.5);
    &:first-child{
      font-size: .9rem;
      font-weight: bold;
      color: $white;
    }
  }
  a{
    color: $white;
  }
}
