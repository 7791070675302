.swiper-container {
  padding: 8px;

  @media #{$mobile} {
    padding: 0px !important;
    margin: 0px !important;
    width: 100vw !important;
  }

  .swiper-slide {
    background: $silver;
    min-height: 45vh;

    .content {
      margin-top: -25px;
      max-width: 35vw;

      @include flex(0, 1, 35vw);

      margin-left: 10vw;
    }

    @include display-flexbox(row, flex-start);
    @include justify-content(stretch);

    align-items: center;

    @media #{$desktop} {
      min-height: 45vh;

      .content {
        margin-top: -25px;
        max-width: 35vw;

        @include flex(0, 1, 35vw);

        margin-left: 10vw;
      }
    }

    @media #{$laptop} {
      min-height: 60vh;

      // done
      .content {
        margin-top: -25px;
        max-width: 35vw;

        @include flex(0, 1, 35vw);

        margin-left: 10vw;
      }
    }

    @media #{$tablet} {
      min-height: 45vh;

      .content {
        margin-top: -25px;
        max-width: 35vw;

        @include flex(0, 1, 35vw);

        margin-left: 10vw;
      }
    }

    @media #{$ipadpro-landscape} {
      min-height: 60vh;

      // done
    }

    @media #{$ipadpro-portrait} {
      min-height: 65vh;

      // done
    }

    @media #{$ipad-landscape} {
      min-height: 55vh;

      // done
      .content {
        margin-top: -25px;
        max-width: 35vw;

        @include flex(0, 1, 35vw);

        margin-left: 10vw;

        h2, h3 {
          font-size: 2rem;
        }

        p {
          font-size: 1.1rem;
        }
      }
    }

    @media #{$ipad-portrait} {

      &:before {
        background-position: top center !important;
      }

      @include display-flexbox(column, center);

      align-items: center;

      @include justify-content(center);

      .content {
        margin-top: 0px;
        max-width: 100%;

        @include flex(1, 1, 100%);

        margin-left: auto;
        margin-right: auto;
        text-align: center;

        h2, h3 {
          font-size: 2rem;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }

    @media #{$tabletportrait-down} {
      min-height: 45vh;
    }

    @media #{$mobile} {

      &:before {
        background-image: none;
      }

      * {
        max-width: 100%;
      }

      .content {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;

        h2, h3 {
          font-size: 1.4rem;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }

    > * {
      z-index: 999;
    }

    &#slide-tax-update-2018 {
      background: $silver;

      @include adaptive-backdrop-photo("taxreformgears");

      .button {
        border-color: $orange;
        color: $white;
        background: $orange;
      }
    }

    &#slide-tax-update-2019 {
      background: $silver;

      @include adaptive-backdrop-photo("taxreformgears");
    }

    &#slide-coronavirus {
      background: $blue;
      @include justify-content(center);

      @include adaptive-backdrop-photo("covid19");

      color: $white;
      .content{
        margin-left: 0;
        max-width: 45rem;
        width: 100%;
      }
      h1, h2, h3, p {
        color: $white;
        text-align: center;
      }
      .actions{
       @media #{$desktop} { 
         @supports (display: grid) {
          display: grid;
          grid-template-columns: lfr lfr lfr;
          grid-gap: 8px;
          grid-template: auto / repeat(3, 1fr);
          grid-auto-flow: row;

          .button{
            @include display-flexbox(column,center);
            @include justify-content(center);
            @include flexbox-align(center);
            @include align-items(center);
            text-align: center;
          }
        }
      }


      @media #{$laptop} { 
         @supports (display: grid) {
          display: grid;
          grid-template-columns: lfr lfr lfr;
          grid-gap: 8px;
          grid-template: auto / repeat(3, 1fr);
          grid-auto-flow: row;

          .button{
            @include display-flexbox(column,center);
            @include justify-content(center);
            @include flexbox-align(center);
            @include align-items(center);
            text-align: center;
          }
        }
      }

      @media #{$tablet-down} { 
         display: block;

          .button{
            @include display-flexbox(column,center);
            @include justify-content(center);
            @include flexbox-align(center);
            @include align-items(center);
            text-align: center;
            margin-top: 8px;
          }
      }

      }
      button, .button {
        color: $white;
        border-color: $white;
      }
    }

    &#slide-tax {
      @include adaptive-backdrop-photo("taxrefund");
    }

    &#slide-realestate {
      color: $navy;
      background: $silver;

      @include adaptive-backdrop-photo("infinitypoolhouse");
    }
  }
}

.swiper-pagination {
  bottom: 6px !important;

  //background: rgba($white,.8);
  padding: 12px;
}

.swiper-pagination-bullet {
  width: 17px;
  height: 17px;

  @include transition;
  @include scale(1);

  border: 1px solid rgba($white, 0.9);
  margin-left: 14px !important;
  margin-right: 14px !important;

  &.swiper-pagination-bullet-active {
    background: $blue;

    @include box-shadow(0 1px 1px rgba($black, 0.6));

    @include scale(1.1);
  }
}
