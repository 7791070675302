// Quick Standard Colors
$navy: 		#001F3F;
$blue: 		darken(#0074D9,10);
$aqua: 		#7FDBFF;
$teal: 		#39CCCC;
$olive: 	#3D9970;
$green: 	#00AF8E;
$lime: 		#92DC2E;
$yellow: 	#FFDC00;
$gold:		#E9A401;
$orange: 	#FF851B;
$red: 		#FF4136;
$maroon: 	#85144B;
$fuchsia: #F012BE;
$purple: 	#B10DC9;
$white: 	#FFFFFF;
$silver: 	#F4F4F4;
$grey: 		#363D50;
$greyblue: 	#3D4B76;
$blueblack: #474F67;
$black: 	#111111;

$alert-color: darken(mix($red,$orange, 80),20);

$font-stack: "Garamond", serif;
$heading-font-stack: "Garamond", serif;
$button-font-stack: "Garamond", serif;

$years-seal-background: mix($blue,$navy);
$base-font-size: 20px;
$min-font-size: 14px;
$font-color: $black;

$reading-width: 51rem;

$background-color-a: $white;
$background-color-b: $white;

$aside-background: none;

$ui-border-color: darken($silver,8);
$h1-color: $black;
$h2-color: $black;
$h3-color: $blue;
$h4-color: $blue;
$h5-color: $blue;

$link-font-color: $blue;
$link-hover-font-color: darken($blue,15);

$nav-link-color: $white;
$nav-background-color: $navy;

$footer-background-color: darken($navy,5);
$footer-font-color: $white;

$coverup-background: rgba(mix($black,$navy, 90%),.96);
$modal-background: rgba($white,.9);
$modal-border-radius: 4px;

$button-font-color: $blue;
$button-bg-color: rgba($white,.2);
$button-border-color: $blue;
$button-border-thickness: 1px;
$button-border-radius: 4px;
$button-line-height: 36px;
$button-padding: 4px 15px;

$button-disabled-font-color: darken($silver,10);
$button-disabled-bg-color: $silver;
$button-disabled-border-color: darken($silver,15);

$button-hover-font-color: $white;
$button-hover-bg-color: $blue;

$button-strong-font-color: $white;
$button-strong-bg-color: $blue;

$button-stronghover-font-color: $white;
$button-stronghover-bg-color: rgba($blue,.9);

$button-neg-font-color: $button-font-color;
$button-neg-bg-color: $button-bg-color;
$button-neg-border-color: $button-border-color;
$button-neg-hover-font-color: $white;
$button-neg-hover-bg-color: rgba($red,.9);
$button-neg-hover-border-color: $red;

$button-active-font-color: $white;
$button-active-bg-color: $green;
$button-active-border-color: $green;

$input-border: 1px solid darken($silver,10);
$input-radius: 4px;
$input-padding: 4px 10px;
$input-font-size: .9rem;
$input-color: $black;
$input-bg: $white;

$input-hover-border: 1px solid $blue;
$input-hover-color: $black;
$input-hover-bg: rgba($blue,.05);

$input-focused-border: 1px solid $blue;
$input-focused-color: $blue;
$input-focused-bg: rgba($blue,.05);
$input-label-color: $font-color;

$card-background-color: $white;
$card-border-width: 1px;
$card-border-radius: 1px;

$grid-card-width: 270px;
$grid-card-height: 210px;
