.accordion-container {
  border: 1px solid transparent;
  border-bottom-color: $ui-border-color;
  //@include border-radius(4px);
  margin-bottom: 20px;
  @include transition();
  @include display-flexbox(column,stretch);
  @include justify-content(stretch);
  &.active{
    border-color: $blue;
    background: $silver;
    a.accordion{
      //background: $blue;
      color: $black;
      background: mix($blue,$white,15);
      @include transition();
      > i{
        @include rotate(90deg);
        svg *{
          fill: $blue;
        }
      }
      + .accordion-bdy{
        max-height: 1300px;
        @include opacity(1);
        padding: 20px 20px;
      }
    }
  }
  a.accordion{
    //@extend .button;
    @include display-flexbox(row,stretch);
    @include justify-content(stretch);
    align-items: flex-start;
    align-content: flex-start;
    border: none;
    text-decoration: none;
    font-size: 1.2rem;
    color: $font-color;
    margin: 4px;
    padding-right: 74px;
    cursor: pointer;
    position: relative;
    &.new{
      color: $blue;
      &:before{
        content: 'NEW';
        background: $blue;
        color: $white;
        display: inline-block;
        font-size: .8rem;
        padding: 5px;
        @include border-radius(2px);
        position: absolute;
        right: 0px;
      }
      @media #{$desktop}{
        &:before{
          content: 'NEW';
          background: $blue;
          color: $white;
          display: inline-block;
          font-size: .8rem;
          padding: 5px;
          @include border-radius(2px);
          position: absolute;
        }
      }
      @media #{$laptop}{
        &:before{
          content: 'NEW';
          background: $blue;
          color: $white;
          display: inline-block;
          font-size: .8rem;
          padding: 4px;
          @include border-radius(2px);
          position: absolute;
        }
      }
      @media #{$tablet-down}{
        &:before{
          content: '';
          width: 11px;
          height: 11px;
          padding: 0;
          @include border-radius(11px);
        }
      }
      @media #{$mobile}{
        &:before{
          content: '';
          width: 11px;
          height: 11px;
          padding: 0;
          @include border-radius(11px);
        }
      }
    }
    > i{
      display: block;
      @include flex(0,0,auto);
      margin-right: 8px;
      @include transition();
      @include transform-origin(center,center);

      svg{
        max-height: 24px;
      }
    }
    + .accordion-bdy{
      padding: 0 20px;
      margin-top: 0px;
      margin-bottom: 0;
      overflow: hidden;
      height: auto;
      max-height: 0px;
      @include opacity(0);
      @include transition();
    }
  }

}
