svg#svg-logo-defs{
  position: absolute;
  z-index: -1;
}
html,
body{
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  @media #{$mobile} {
    overflow-x: hidden;
  }
  > div{
    // this is our wrapper.
    @include display-flexbox(column,stretch);
    @include justify-content(stretch);
    align-items: stretch;
    align-content: stretch;
    max-width: 100vw;

    @include linear-gradient($background-color-a,rgba($background-color-b,.6));
    > section.workspace{
      @include flex(1,1,auto);
      @include display-flexbox(row,flex-start);
      @include justify-content(center);
      align-items: flex-start;
      align-content: flex-start;
      padding: 0;
      @media #{$desktop} {
        align-content: center;
      }
      @media #{$mobile} {
        @include display-flexbox(column,stretch);
        @include justify-content(stretch);
        align-items: stretch;
        align-content: stretch;
        padding: 0px;
      }

      @include flex-wrap();
      .main{
        display: block;
        width: 100%;

        @media #{$mobile} {
          min-width: 110px;
          //padding: 20px;
        }
      }
    }
  }
}


h1,h2{
  font-size: 2.75rem;
  //text-transform: uppercase;
  font-family: $heading-font-stack;
  font-weight: bold;
  color: $blue;
  *{
    font-weight: bold;
  }
}
h3{
  font-size: 1.45em;
  font-weight: bold;
  font-family: $heading-font-stack;
}
h4{
  font-size: 1.2rem;
}

p,
p *,
p a{
  line-height: 1.5rem;
  font-size: 1.25rem;
}

ul.check-list{
  list-style: none;
  margin: 0;
  padding: 0;

  li{
    border-bottom: 1px solid rgba($ui-border-color,.6);
    padding-top: 8px;
    padding-bottom: 8px;
    @include display-flexbox(row,center);
    align-content: center;
    position: relative;

    &:before{
      content: '';
      width: 34px;
      height: 34px;
      display:block;
      background: $green;
      @include border-radius(44px);
      margin-right: 8px;
    }
    &:after{
      content: '';
      display:block;
      position: absolute;
      left: 0px;
      top: 1px;
      bottom: 0;
      width: 32px;
      background: url('data:image/svg+xml;utf8,<svg width="13px" height="10px" viewBox="0 0 13 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M4.55047562,9.1005325 C4.37806097,9.1005325 4.2127964,9.03211915 4.09091988,8.91024262 L0.19087113,5.01003137 C-0.0629570432,4.7562032 -0.0629570432,4.34474805 0.19087113,4.09091988 C0.444699302,3.83709171 0.856316948,3.83709171 1.10998262,4.09091988 L4.55047562,7.53141289 L11.8910174,0.19087113 C12.1448456,-0.0629570432 12.5564632,-0.0629570432 12.8101289,0.19087113 C13.063957,0.444699302 13.063957,0.856316948 12.8101289,1.11014512 L5.01003137,8.91024262 C4.88815485,9.03211915 4.72289028,9.1005325 4.55047562,9.1005325 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path></g></svg>') center center no-repeat;
    }
  }
}
ul.actions{
  list-style: none;
  margin: 0;
  padding: 0;
  @include display-flexbox(row,flex-start);

  li{
    display:block;
    @include flex(0,0,auto);
  }
}
.map{
  margin-top: 30px;
  iframe,iFrame{
    background: darken($silver,10);
    @media #{$tabletportrait-down} {
      width: 90%;
    }
  }
}

em{
  font-style: normal;
  font-weight: bold;
}
