.tag:not(:empty){
  display:block;
  padding: 3px 10px;
  color: $white;
  width: auto;
  @include flex(0,0,auto);
  background: $blue;
  @include border-radius(22px);
  margin: 4px;
  font-size: .8rem;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    background: $blue;
  }
}
.tag-list{
  list-style: none;
  margin: 0;
  padding: 0;
  @include display-flexbox(row,stretch);
  @include justify-content(flex-start);
  @include flex-wrap();
  li{
    display:block;
    margin: 0;
    padding: 0;
    @include flex(0,0,auto);
    margin-right: 10px;
    @extend .tag;
    &:last-child{
      margin-right: 0;
    }
  }
}
li{
  position: relative;
  &.new{
    color: $blue;

    &:before{
      content: 'NEW';
      background: $blue;
      color: $white;
      display: inline-block;
      font-size: .8rem;
      padding: 5px;
      @include border-radius(2px);
      float: right;
    }

  }
  &.updated{
    color: $blue;

    &:before{
      content: 'UPDATED';
      background: $blue;
      color: $white;
      display: inline-block;
      font-size: .8rem;
      padding: 5px;
      @include border-radius(2px);
      float: right;
    }

  }
}
