ul.resource-list{

  list-style: none;
  margin: 0;
  padding: 0;

  a{
    text-decoration: none;
    color: $font-color;
  }
  > li{
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid $ui-border-color;
  }
  .actions{
    margin-top: 8px;
    margin-bottom: 8px;
    li{
      margin-right: 8px;
    }
  }
}
.resource-sidebar{
  @include sticky();
  top: 150px;
}

.banded .content ul ul,
ul ul{
  &.sub-list-reg{
    background: none;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
