$path-to-font: 'assets/fonts/';

html,
body{
  font-family: $font-stack;
  font-size: $base-font-size;
  color: $font-color;
  * {
    font-weight: normal;
  }
  @media #{$desktop}{
    font-size: 18px;
  }
  @media #{$tablet-down}{
    font-size: 16px;
  }
  @media #{$tabletportrait-down}{
    font-size: 16px;
  }
  @media #{$mobile}{
    font-size: 14px;
  }

}

@font-face {
	font-family: 'PTSerif';
	src: url('fonts/PTF55F_W.eot');
	src:
	     url('fonts/PTF55F_W.eot?#iefix') format('embedded-opentype'),
	     url('fonts/PTF55F_W.woff') format('woff'),
	     url('fonts/PTF55F_W.ttf') format('truetype'),
	     url('fonts/PTF55F_W.svg#PTSerif') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'BodoniXT';
    src: url('fonts/BodoniXT-webfont.eot');
    src: url('fonts/BodoniXT-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/BodoniXT-webfont.woff') format('woff'),
         url('fonts/BodoniXT-webfont.ttf') format('truetype'),
         url('fonts/BodoniXT-webfont.svg#bodonixtregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
