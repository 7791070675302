nav{
  @include flex(0,0,auto);
  //background: $nav-background-color;
  @include linear-gradient(darken($nav-background-color,6), $nav-background-color);
  @include display-flexbox(column,center);
  @include justify-content(center);
  @media #{$mobile-up} {
    @include sticky;
  }
  @media #{$mobile} {
    @include display-flexbox(column,stretch);
    @include justify-content(stretch);
    width: 100vw;
    position: relative;
    li{
      width: 100%;
    }

  }
  top: 0;
  z-index: 999;
  ul.nav{
    margin: 0;
    padding: 0;
    list-style: none;
    @include display-flexbox(row,center);
    @include justify-content(center);

    width: 100%;

    > li{
      @include flex(0,0,auto);
      display: block;
      a{
        display: block;
        padding: 15px 40px;
        border-top: 4px solid rgba($blue,0);
        font-family: 'Garamond';
        font-weight: bold;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        color: rgba($white,.8);
        @include transition();
        cursor: pointer;

        span{
          font-family: 'Garamond';
          font-weight: bold;
        }
        @media #{$laptop} {
          span{
            display:none;
          }
        }
        @media #{$tablet-down} {
          span{
            display:none;
          }
        }
        @media #{$mobile} {
          padding: 15px 10px;
          font-size: .7rem;
          span{
            display:none;
          }
        }

        &.active{
          color: $white;
          background: $nav-background-color;
          @include linear-gradient($navy,mix($blue,$navy));
          border-top: 4px solid $orange;
        }
        &:hover:not(.active){
          background: lighten($navy,4);
          color: $white;
          border-top: 4px solid mix($black,$navy);
          @include box-shadow(inset 0 4px 8px rgba($black,.53));
        }
      }
    }
  }
}
