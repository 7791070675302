// Mixins

@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin opacity($opacity) {
  -webkit-opacity: $opacity;
     -moz-opacity: $opacity;
      -ms-opacity: $opacity;
          opacity: $opacity;
}
@mixin box-shadow($string...) {
  -webkit-box-shadow: $string;
     -moz-box-shadow: $string;
      -ms-box-shadow: $string;
          box-shadow: $string;
}
@mixin blur($amount) {
	    -ms-filter: blur($amount);
	   -moz-filter: blur($amount);
  -webkit-filter: blur($amount);
	        filter: blur($amount);
}
@mixin backdrop-blur($amt){ // currently only works in latest safari
  -webkit-backdrop-filter: blur($amt);
  backdrop-filter: blur($amt);
}
@mixin filter($args...){
  -webkit-filter: $args;
                  filter: $args;
}
@mixin transition($args:all .25s ease-in-out) {
  -webkit-transition: $args;
     -moz-transition: $args;
      -ms-transition: $args;
          transition: $args;
}
@mixin transitioncustom($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
      -ms-transition: $args;
          transition: $args;
}
@mixin clearFloatAfter(){
	&:after{
		content:"";
		display:block;
		clear: both;
	}
}
@mixin scale($value){
	-webkit-transform: scale($value);
	   -moz-transform: scale($value);
	    -ms-transform: scale($value);
	        transform: scale($value);
}
@mixin rotate($value){
	-webkit-transform: rotate($value);
	   -moz-transform: rotate($value);
	    -ms-transform: rotate($value);
	        transform: rotate($value);
}
@mixin rotateandscale($deg,$scale){
	-webkit-transform: rotate($deg) scale($scale);
	   -moz-transform: rotate($deg) scale($scale);
	    -ms-transform: rotate($deg) scale($scale);
	        transform: rotate($deg) scale($scale);
}
@mixin transform($string){
	-webkit-transform: $string;
       -moz-transform: $string;
        -ms-transform: $string;
            transform: $string;
}
@mixin transform-origin($x:center, $y:center){
	-webkit-transform-origin: $x $y;
	   -moz-transform-origin: $x $y;
	    -ms-transform-origin: $x $y;
	        transform-origin: $x $y;
}
@mixin preserve3d(){
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
@mixin perspective($string){
	-webkit-perspective: $string;
       -moz-perspective: $string;
        -ms-perspective: $string;
            perspective: $string;
}
@mixin backface-hidden(){
  -moz-backface-visibility: hidden;
  //-ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
@mixin perspective-origin($x, $y){
	-webkit-perspective-origin: $x $y;
       -moz-perspective-origin: $x $y;
        -ms-perspective-origin: $x $y;
            perspective-origin: $x $y;
}
@mixin linear-gradient($startColor, $endColor) {
	background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
	background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image: linear-gradient(top, $startColor, $endColor); // The standard
	background-repeat: repeat-x;
}

// icon fonts
@mixin iconfont($fontname:'FontAwesome'){
	font-family: $fontname;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin iconcontent($icon,$fontname:'scicons'){
	@include iconfont($fontname);
	content: $icon;
}
@mixin addicon($icon,$position:before,$fontname:'scicons'){
	&:#{$position}{
		@include iconcontent($icon,$fontname);
	}
}
// For Flexbox containers
// Current Flexbox
@mixin flexbox($important:null) {
	display: -webkit-box$important;
	display: -moz-box$important;
	display: -ms-flexbox$important;
	display: -webkit-flex$important;
	display: flex$important;
}

@mixin order($val) {
	-webkit-box-ordinal-group: $val;
	   -moz-box-ordinal-group: $val;
	    -ms-flex-order:        $val;
	     -webkit-order:        $val;
		 		 order:        $val;
}
@mixin display-flexbox($direction:row, $align:stretch){
	@include flexbox();
	@include flexbox-direction($direction);
	@include flexbox-align($align);
}
@mixin flexbox-direction($direction){
	-webkit-flex-direction: $direction;
	   -moz-flex-direction: $direction;
	    -ms-flex-direction: $direction;
	        flex-direction: $direction;
}
@mixin flexbox-align($align){
	-webkit-flex-align: $align;
	   -moz-flex-align: $align;

	    -ms-flex-align: $align;
		    flex-align: $align;

	-webkit-align-items: $align;
	   -moz-align-items: $align;
	    -ms-align-items: $align;
		   -align-items: $align;

		-ms-flex-pack: $align; // for ie10
	@if $align == flex-end {
		-ms-flex-pack: end; // for ie10
	}
	@if $align == flex-start {
		-ms-flex-pack: start; // for ie10
	}
}
@mixin justify-content($type){
	-webkit-justify-content: $type;
	   -moz-justify-content: $type;
	    -ms-justify-content: $type;
	        justify-content: $type;

	        -webkit-align-content: $type;
			   -moz-align-content: $type;
			    -ms-align-content: $type;
				    align-content: $type;
}
@mixin align-items($type){
	-webkit-align-items: $type;
	        align-items: $type;
}
@mixin flex($grow:1,$shrink:1,$basis:auto){
	-webkit-box-flex: $grow $shrink $basis;
	   -moz-box-flex:  $grow $shrink $basis;
	    -webkit-flex:  $grow $shrink $basis;
		    -ms-flex:  $grow $shrink $basis;
			    flex:  $grow $shrink $basis;

		//-webkit-flex: $grow $shrink $basis;
		//-webkit-flex: $grow $shrink $basis;
	       //-moz-flex: $grow $shrink $basis;
	        //-ms-flex: $grow $shrink $basis;
		    	//flex: $grow $shrink $basis;
	width: $basis;

}

@mixin flex-wrap($type:wrap){
		-webkit-flex-wrap: $type;
	       -moz-flex-wrap: $type;
	        -ms-flex-wrap: $type;
		    	flex-wrap: $type;
}
@mixin flexorder($order:1){
	    -ms-box-ordinal-group: $order;
	-webkit-box-ordinal-group: $order;
	   -moz-box-ordinal-group: $order;
			box-ordinal-group: $order;
			   -ms-flex-order: $order;
			        -ms-order: $order;
				-webkit-order: $order;
				   -moz-order: $order;
				        order: $order;
}

@mixin columns($count: 2, $width: 65rem){
         -moz-column-count: $count;
    -webkit-column-count: $count;
                    column-count: $count;

         -moz-column-width: $width;
    -webkit-column-width: $width;
                    column-width: $width;
}
@mixin sticky(){
  position: -ms-sticky;
  position: -moz-sticky;
  position: -webkit-sticky;
  position: sticky;
}
@mixin adaptive-backdrop-photo($imagename){
  position: relative;
  &:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    z-index: 0;
    @media #{$mobile} {
      .no-webp &{
        background: url('images/backdrop/#{$imagename}-mobile-portrait.jpg') center center;
        background-size: cover;
      }
      .webp &{
        background: url('images/backdrop/#{$imagename}-mobile-portrait.webp') center center;
        background-size: cover;
      }
    }
    @media #{$tablet} {
      .no-webp &{
        background: url('images/backdrop/#{$imagename}-tablet-portrait.jpg') center center;
        background-size: cover;
      }
      .webp &{
        background: url('images/backdrop/#{$imagename}-tablet-portrait.webp') center center;
        background-size: cover;
      }
    }
    @media #{$tablet-landscape} {
      .no-webp &{
        background: url('images/backdrop/#{$imagename}-tablet-landscape.jpg') center center;
        background-size: cover;
      }
      .webp &{
        background: url('images/backdrop/#{$imagename}-tablet-landscape.webp') center center;
        background-size: cover;
      }
    }
    @media #{$laptop} {
      .no-webp &{
        background: url('images/backdrop/#{$imagename}-tabletplus-landscape.jpg') center center;
        background-size: cover;
      }
      .webp &{
        background: url('images/backdrop/#{$imagename}-tabletplus-landscape.webp') center center;
        background-size: cover;
      }
    }
    @media #{$desktop} {
      .no-webp &{
        background: url('images/backdrop/#{$imagename}-desktopxl.jpg') center center;
        background-size: cover;
      }
      .webp &{
        background: url('images/backdrop/#{$imagename}-desktopxl.webp') center center;
        background-size: cover;
      }
    }
  }
}
