@mixin mobileBandedReset {
  @media #{$mobile} {
    max-width: 100% !important;
    padding: 70px !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    > * {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.banded {
  @include display-flexbox(row, center);
  @include justify-content(center);

  align-items: center;
  align-content: center;
  max-width: 100vw;
  min-height: 29rem;
  .news &{
    min-height: 2rem;
  }
  dl, dd, dt {
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
  }

  dd {
    margin-bottom: 1rem;
  }

  dt {
    font-weight: bold;
    font-size: 1.1rem;
  }

  &.min {
    min-height: 11rem;
  }

  &.alert {
    background: rgba($red, 0.1);
    border-top: 1px solid $red;
    border-bottom: 1px solid $red;

    button, a.button {
      border-color: $red;
      color: $white;
      background: $red;

      &:hover {
        background: darken($red, 5);
        color: $white;
      }
    }
  }

  @media #{$mobile} {
    min-height: 100px !important;

    @include display-flexbox(column, stretch);
    @include justify-content(stretch);

    align-items: stretch;
    align-content: stretch;
    align-self: stretch;
    max-width: 100% !important;
  }

  .content {
    @include flex(0, 0, auto);

    width: $reading-width;
    max-width: 100%;
    padding: 20px;

    @media #{$mobile} {
      width: auto;

      @include flex(1, 1, auto);

      padding: 20px;
      margin: 0;
      max-width: 100vw !important;
    }

    @media #{$tabletportrait-down} {
      width: auto;

      @include flex(1, 1, auto);

      padding: 20px;
      margin: 0;
      max-width: 100vw !important;
    }

    z-index: 2;

    p,
    p *,
    ul,
    ul *,
    ol,
    ol * {
      line-height: 1.5rem;
      font-size: 1.25rem;
    }

    ul,
    ol {
      li {
        padding-bottom: 25px;

        &:not(:first-child) {
          padding-top: 25px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $ui-border-color;
        }
      }

      ul,
      ol {
        background: $silver;
        padding-top: 25px;
        padding-bottom: 25px;

        li {
          padding: 8px;
        }
      }

      max-width: 45rem;
    }
  }

  img.row-img {
    z-index: 2;
  }
}

#contact {
  background: $silver;

  /*.content{
    max-width: $reading-width;
    width: 100%;
  }*/
}

// Tax route
//
#tax-prep {
  background: $silver;
  text-align: center;

  .content {
    max-width: 35rem;
  }
}

#self-employed {}

#tax-why-sld {
  background: $silver;
  min-height: 400px;

  .content {
    //width: $reading-width;

    @media #{$mobile} {
      padding: 20px;
    }
  }
}

#tax-why-sld-top-ten-list {
  background: $silver;

  .content {
    ol + p {
      text-align: center;
      padding-bottom: 80px;
    }
  }

  .content ol {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: $reading-width;

    @media #{$mobile} {
      max-width: 100%;
    }

    li {
      padding: 20px 8px;

      p {
        text-align: left;
      }
    }

    h3 {
      margin: 0;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: lfr lfr;
      grid-gap: 0px;
      grid-template: auto / repeat(2, 1fr);
      grid-auto-flow: row;

      /* or 'row', 'row dense', 'column dense' */
      margin-bottom: 8px * 10;
      border-top: 3px solid $ui-border-color;
      border-left: 3px solid $ui-border-color;

      li,
      li:first-child,
      li:last-child {
        margin: 0 !important;
        padding: 8px 20px;
        border-bottom: 3px solid $ui-border-color;
        border-right: 3px solid $ui-border-color;
      }

      @media only screen and (max-width: 1080px) {
        grid-template: auto / repeat(2, 1fr);
        max-width: 100%;
      }

      @media only screen and (max-width: 800px) {
        display: block;

        > li {
          margin-bottom: 10px;
        }
      }
    }
  }
}

#tax-services {
  background: $white;

  .content {
    h2 {
      text-align: center;
    }

    ul.grid {
      list-style: none;
      margin: 0;
      padding: 10px;

      @media #{$mobile} {
        padding: 0;
      }

      max-width: 100%;
      border: 1px solid $ui-border-color;
      background: $white;

      + p {
        text-align: center;
        padding-bottom: 80px;
      }

      @supports (display: grid) {
        display: grid;
        grid-template-columns: lfr lfr lfr;
        grid-gap: 0px;
        grid-template: auto / repeat(3, 1fr);
        grid-auto-flow: row;

        /* or 'row', 'row dense', 'column dense' */

        li,
        li:first-child,
        li:last-child {
          margin: 0 !important;
          padding: 18px;

          @media #{$mobile} {
            padding: 8px;
          }

          @media #{$tablet-down} {
            padding: 10px;
          }

          text-align: center;
          border: 1px solid $ui-border-color;

          @include display-flexbox(column, center);
          @include justify-content(center);

          background: $white;
          height: 70px;

          @media #{$mobile} {
            height: auto;
          }

          @media #{$tablet-down} {
            height: auto;
          }
        }

        @media #{$tabletportrait-down} {
          grid-template: auto / repeat(2, 1fr);
          max-width: 100%;
        }

        @media only screen and (max-width: 800px) {
          display: block;

          > li {
            margin-bottom: 10px;
          }
        }

        ul.columns {
          margin: 10px !important;
          padding: 0 !important;
          background: $white;

          li {
            margin: 8px;

            @media #{$tablet-down} {
              margin: 2px;
            }

            &:not(:last-child) {
              border-bottom: none;
            }
          }

          &#personal-tax-services {
            li {
              background: mix($grey, $white, 3);
            }
          }

          &#smallbusiness-tax-services {
            li {
              background: mix($blue, $white, 3);
            }
          }

          &#entity-tax-services {
            li {
              background: mix($green, $white, 3);
            }
          }
        }
      }
    }
  }
}

#tax-business {
  background: $white;

  @include adaptive-backdrop-photo("twotaxguys");

  &:before {
    background-position: center right !important;
  }

  @media #{$mobile-up} {

    .content {
      max-width: 35rem;
      text-align: right;
      margin-right: 34vw;
      position: relative;
    }
  }
}

#tax-rental {
  background: $silver;

  @include adaptive-backdrop-photo("investmentprop");

  @media #{$mobile-up} {

    .content {
      max-width: 35rem;
      text-align: left;
      margin-left: 34vw;
      position: relative;

      > * {
        z-index: 999;
      }

      /*&:before{
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        background: rgba($white,.9);
        display:block;
        z-index: -1;
        @include blur(40px);
      }*/
    }
  }
}

#tax-resources {
  background: $white;
}

// Tax Reform Update (2018)
#update-2018-intro {
  background: $silver;
}

#update-2018-strategies {
  .content {
    h2 {
      text-align: center;
    }

    max-width: 45rem;
  }
}

#update-2018-faq {
  background: $white;
}

#update-2018-wisdom {
  //background: $silver;

  .content {
    h2 {
      text-align: center;
    }

    max-width: 45rem;
  }
}

#update-2018-disclaimer {
  min-height: 100px;
  padding: 80px;
  background: $silver;
}

// Real Estate route
//
#real-estate {
  background: #3767BB;

  @include adaptive-backdrop-photo("housedarksky");

  &:before {
    background-position: top center !important;
  }

  color: $white;

  h2 {
    color: $white;
  }

  .button {
    border-color: $white;
    color: $white;
  }

  @media #{$mobile-up} {

    .content {
      text-align: center;
      max-width: 47rem;
      position: relative;

      > * {
        z-index: 999;
      }

      @supports (-webkit-filter: blur(40px)) {
        &:before {
          content: '';
          position: absolute;
          left: 10px;
          right: 10px;
          top: 40px;
          bottom: 10px;
          background: rgba($navy, 0.4);
          display: block;
          z-index: -1;

          @include blur(40px);
        }
      }
    }
  }
}

#first-time-home-buyers {
  background: $silver;

  @include adaptive-backdrop-photo("woodkey");

  @media #{$mobile-up} {

    .content {
      max-width: 25rem;
      text-align: center;
      margin-left: 22vw;
    }
  }
}

#home-purchase {
  background: $silver;

  @include adaptive-backdrop-photo("infinitypoolhouse");

  @media #{$mobile-up} {

    .content {
      max-width: 25rem;
      text-align: right;
      margin-right: 18vw;
    }
  }
}

#home-sale {
  background: $silver;

  @include adaptive-backdrop-photo("housewithpool");

  &:before {
    background-position: top center !important;
  }

  @media #{$mobile-up} {

    .content {
      position: relative;
      max-width: 45rem;
      text-align: center;
      align-self: flex-start;
      margin-top: 80px;

      > * {
        z-index: 999;
      }

      &:before {
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        background: rgba($white, 0.9);
        display: block;
        z-index: -1;

        @include blur(40px);
      }
    }
  }
}

#investment-property {
  background: $silver;

  @include adaptive-backdrop-photo("investmentprop");

  @media #{$mobile-up} {

    .content {
      max-width: 35rem;
      text-align: left;
      margin-left: 34vw;
      position: relative;

      > * {
        z-index: 999;
      }

      /*&:before{
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        background: rgba($white,.9);
        display:block;
        z-index: -1;
        @include blur(40px);
      }*/
    }
  }
}

#property-management {
  @media #{$mobile-up} {

    .content {
      max-width: 25rem;
      text-align: right;
      margin-right: 30px;
    }
  }

  @media #{$mobile} {

    img {
      display: none;
    }
  }
}

// About route
//
#about-sld {
  background: $silver;
}

#about-personal {
  background: $white;
}

#relocation {}

#real-estate-disclaimers {
  background: $silver;
  min-height: 10vh;
  padding: 10px;

  .content {
    @include display-flexbox(row, center);

    text-align: center;

    > div {
      padding: 25px;
    }

    i {
      max-width: 70px;
      max-height: 70px;
      width: 70px;
      height: 70px;
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      svg {
        width: 100%;
        height: 100%;
        max-width: 70px;
        max-height: 70px;
      }
    }
  }
}

#realestate-disclaimer-logos.content {
  @include display-flexbox(row, center);
  @include justify-content(center);

  @media #{$mobile} {
    display: block;
  }
}


