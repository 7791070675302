@mixin button-style(){
  display:inline-block;
  text-decoration: none;
  color: $button-font-color;
  background: $button-bg-color;
  border: $button-border-thickness solid $button-border-color;
  font-family: $button-font-stack;
  font-size: 1.35rem;
  font-weight: bold;
  padding: $button-padding;
  @include border-radius($button-border-radius);
  @include transition();
  cursor: pointer;

  &.sm{
    padding: 4px;
    line-height: 1.1rem;
    font-size: .8rem;
  }

  &:hover:not([disabled]){
    color: $button-hover-font-color;
    background: $button-hover-bg-color;
  }
  &.active{
    color: $button-active-font-color;
    background: $button-active-bg-color;
    border-color: $button-active-border-color;
  }
  &.strong,
  &.submit,
  &[type="submit"]{
    color: $button-strong-font-color;
    background: $button-strong-bg-color;
    &:hover:not([disabled]){
      color: $button-stronghover-font-color;
      background: $button-stronghover-bg-color;
    }
  }
  &.neg{
    color: $button-neg-font-color;
    background: $button-neg-bg-color;
    border-color: $button-neg-border-color;
    &:hover:not([disabled]){
      color: $button-neg-hover-font-color;
      background: $button-neg-hover-bg-color;
      border-color: $button-neg-hover-border-color;
    }
  }
  &[disabled]{
    cursor: not-allowed;
    color: $button-disabled-font-color;
    background: $button-disabled-bg-color;
    border-color: $button-disabled-border-color;
  }
}
@mixin link-style(){
  display: inline;
  font-weight: 600;
  color: $link-font-color;
  text-decoration: none;
  @include transition();
  &:hover{
    color: $link-hover-font-color;
  }
}
.button{
  @include button-style();
}
.workspace{
  button,
  .button{
    @include button-style();
  }

  p a{
    @include link-style();
  }
}
.formactions a{
  padding: $button-padding;
  line-height: $button-line-height;
}
