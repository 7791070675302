table{
  td,th{
    padding: 15px;
    border-bottom: 1px solid $ui-border-color;
    &:first-child{
      border-right: 1px solid $ui-border-color;
    }
  }
  thead{
    background: darken($silver,5);
  }
  tbody{
    tr{
      td{

      }
      &.category{
        background: rgba($blue,.15);
        &:nth-child(odd){
          background: rgba($blue,.2);
        }
      }
      &:nth-child(odd){
        background: rgba($silver,.6);
      }
    }
  }
}
