.main {
  position: relative;
}

a#mobile-tax-reform-update,
a#covid19-impact {
  //display: none;

  @media #{$mobile} {
    @include display-flexbox(row, center);
    @include justify-content(center);

    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    color: $alert-color;
    border-color: $alert-color;

    i {
      max-width: 34px;
      max-height: 34px;
      display: block;

      @include flex(0, 0, auto);

      svg {
        max-width: 34px;
        max-height: 34px;

        * {
          fill: $alert-color;
        }
      }
    }
  }
}

nav.subnav {
  padding-top: 8px;
  padding-bottom: 8px;
  top: 54px;
  bottom: 0;
  background: $white;
  max-width: 100vw;
  width: 100%;

  //border-bottom: 1px solid $ui-border-color;

  &:not(.alert) {
    @media #{$mobile} {
      display: none;
    }
  }

  @media #{$mobile} {
    //position: relative;
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @include display-flexbox(row, stretch);

    @media #{$mobile-up} {
      @include sticky;
    }

    @media #{$mobile} {
      //position: relative;
      @include sticky;

      li {
        width: 100%;
      }
    }
  }

  li {
    display: block;

    @include flex(1, 1, auto);

    &:not(:last-child) a {
      border-right: 1px solid rgba($white, 0.3);
    }
  }

  &.alert {
    li {
      a {
        color: $alert-color;

        &.active {
          color: $white;
          background: $alert-color;
        }
      }
    }
  }

  a {
    text-decoration: none;
    color: $navy;
    display: block;
    text-align: center;
    padding: 15px;

    @media #{$tabletportrait-down} {
      padding: 10px;
    }

    font-family: 'Garamond';
    font-weight: bold;
    position: relative;
    border-top: 1px solid $ui-border-color;
    margin-left: 4px;
    margin-right: 4px;

    @include border-radius(1px);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 50%;
      border-left: 1px solid $ui-border-color;
    }

    &:before {
      left: 0px;
    }

    @media #{$mobile} {
      padding: 10px 5px;
      margin-bottom: 2px;
    }

    @include transition;

    &:active,
    &.active {
      background: $navy;

      @include linear-gradient($navy, mix($blue, $navy));

      color: $white;

      &:before,
      &:after {
        display: none;
      }
    }

    &:not(.active):hover {
      @include linear-gradient(mix($navy, $white, 20%), $white);
      @include box-shadow(inset 0 4px 8px rgba($black, 0.3));

      color: $blue;
    }

    &.nav-alert {
      font-size: 1rem;

      @include display-flexbox(row, stretch);

      &:hover,
      &.active {
        background: $blue;
        color: $white;

        i svg * {
          fill: $white;
        }
      }

      i {
        @include flex(0, 0, 34px);

        max-width: 22px;
        max-height: 22px;
        display: block;
        margin-right: 8px;

        svg {
          max-width: 18px;
          max-height: 18px;

          * {
            fill: $grey;
          }
        }

        &:last-child {
          margin-left: 8px;
          margin-right: 0px;
        }
      }
    }
  }
}
