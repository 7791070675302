h2 + form{
  margin-top: 25px;
}
form{
  @include display-flexbox(column,flex-start);

  h2,h3,h4{
    &:not(:first-child){
      margin-top: 35px;
    }
    + p{
      margin-top: 5px;
    }
  }

  h3,h4,h5:first-child{
    font-weight: bold;
    color: $blue;
  }
  label:first-child{
    color: $input-label-color;
  }
  .inline-help{
    display:block;
    text-decoration: none;
    font-style: italic;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.formblock{
  @include display-flexbox(column,stretch);
  padding: 6px 0;
  margin-bottom: 10px;
  .formblock{
    margin-bottom: 0; // reduce the child formblocks
  }
  > label{
    + p{
      margin-top: 0;
    }
  }
  > label:first-child{
    text-align: left;
    padding-right: 8px;
    margin-bottom: 4px;
  }

  &.checkbox-group{
    @include display-flexbox(row,stretch);
    align-content: center;
    input[type="checkbox"]{
      margin-right: 8px;
    }
    label{
      @include display-flexbox(row,center);
      align-content: center;
    }
  }
  .addon{
    @include flex(0,0,auto);
  }
}
ul.formactions{
  @include display-flexbox(row,stretch);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  li{
    display:block;
    &:first-child:not(:last-child){
      margin-right: 8px;
    }
    &:first-child{
      a:not(.button){
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
  a,button{
    display:block;
  }
}

@mixin inputStyle(){
  font-size: $input-font-size;
  padding: $input-padding;
  border: $input-border;
  background: $input-bg;
  @include border-radius($input-radius);
  font-size: $input-font-size;
  @include transition();
  width: 100%;
  line-height: 29px;
  box-sizing: border-box;
  &.qty,
  &.minutes{
    width: 55px;
  }
  &[type="url"]{
    width: 35rem;
  }
  &:hover{
    border: $input-hover-border;
    background: $input-hover-bg;
  }

  &:focus{
    border: $input-focused-border;
    background: $input-focused-bg;
  }

  &::placeholder{
    color: darken($silver,10);
  }
}
input{
  &[type="text"],
  &[type="username"],
  &[type="password"],
  &[type="email"],
  &[type="tel"],
  &[type="url"],
  &[type="number"],
  &[type="search"],
  &[type="date"],
  &[type="month"]{
    @include inputStyle();
    min-width: 350px;
  }
}
input{
  &[type="search"]{
    min-width: 100px;
    @include transition();

    &:active,
    &:focus{
      min-width: 400px;
    }
    @include border-radius(22px);
  }
}
textarea{
  @include inputStyle();
  min-height: 75px;
  min-width: 350px;
}

input{
  &[type="checkbox"],
  &[type="radio"]{
    margin: 3px 0.5ex;
    &:first-child{
      margin-right: 5px;
    }
    + label{
      cursor: pointer;
    }
  }
}
a.master-checkbox{
  margin: 3px 0.5ex;
  &:first-child{
    margin-right: 5px;
  }
  cursor: pointer;
}
input[type="checkbox"],
a.master-checkbox{
  position: relative;
  width: 22px;
  height: 22px;
  display: block;
  text-decoration: none;
  &:checked,
  &.isChecked{
    &:before{
      background: $blue;
    }
    &:after{
      @include scale(1);
    }
  }

  &:before,
  &:after{
    content: '';
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    @include border-radius($input-radius);
    @include transition();
  }
  &:before{
    border: $input-border;
    background: $input-bg;
    margin-right: 8px;
  }
  &:after{
    position: absolute;
    left: 1px;
    top: 1px;
    background: url('data:image/svg+xml;utf8,<svg width="13px" height="10px" viewBox="0 0 13 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M4.55047562,9.1005325 C4.37806097,9.1005325 4.2127964,9.03211915 4.09091988,8.91024262 L0.19087113,5.01003137 C-0.0629570432,4.7562032 -0.0629570432,4.34474805 0.19087113,4.09091988 C0.444699302,3.83709171 0.856316948,3.83709171 1.10998262,4.09091988 L4.55047562,7.53141289 L11.8910174,0.19087113 C12.1448456,-0.0629570432 12.5564632,-0.0629570432 12.8101289,0.19087113 C13.063957,0.444699302 13.063957,0.856316948 12.8101289,1.11014512 L5.01003137,8.91024262 C4.88815485,9.03211915 4.72289028,9.1005325 4.55047562,9.1005325 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path></g></svg>') center center no-repeat;
    @include scale(0);
  }
}
input[type="radio"]{
  position: relative;
  width: 22px;
  height: 22px;
  &:checked{
    &:before{
      background: $green;
    }
    &:after{
      @include scale(1);
    }
  }

  &:before,
  &:after{
    content: '';
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    @include border-radius(22px);
    @include transition();
  }
  &:before{
    border: $input-border;
    background: $input-bg;
    margin-right: 8px;
  }
  &:after{
    position: absolute;
    left: 1px;
    top: 1px;
    background: url('data:image/svg+xml;utf8,<svg width="13px" height="10px" viewBox="0 0 13 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M4.55047562,9.1005325 C4.37806097,9.1005325 4.2127964,9.03211915 4.09091988,8.91024262 L0.19087113,5.01003137 C-0.0629570432,4.7562032 -0.0629570432,4.34474805 0.19087113,4.09091988 C0.444699302,3.83709171 0.856316948,3.83709171 1.10998262,4.09091988 L4.55047562,7.53141289 L11.8910174,0.19087113 C12.1448456,-0.0629570432 12.5564632,-0.0629570432 12.8101289,0.19087113 C13.063957,0.444699302 13.063957,0.856316948 12.8101289,1.11014512 L5.01003137,8.91024262 C4.88815485,9.03211915 4.72289028,9.1005325 4.55047562,9.1005325 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path></g></svg>') center center no-repeat;
    @include scale(0);
  }
}

.view-toggle-group{
  @include display-flexbox(row,center);

  input[type="radio"]{
    position: absolute;
    z-index: -1;
    @include opacity(0);
    display:none;

    &:checked + label{
      color: $button-active-font-color;
      background: $button-active-bg-color;
      &:hover:not([disabled]){
        color: $button-stronghover-font-color;
        background: $button-stronghover-bg-color;
      }
    }
  }
  label{
    @include button-style();
    @include border-radius(0);
    margin: 0;
    &[for="list-display-grid"]{
      @include border-radius($button-border-radius 0 0 $button-border-radius);
    }
    &[for="list-display-table"]{
      @include border-radius(0  $button-border-radius  $button-border-radius 0);
    }
  }
}
